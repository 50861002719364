import { SimpleText } from 'components/sanaText';
import { formatAsPrice } from 'utils/format';
import { Table } from 'components/primitives/table';
import { useCurrencyInfo } from 'utils/hooks';
import { memo, useState, useRef, useEffect } from 'react';
import { useFormatNumberWithCulture } from 'utils/hooks';
import PropTypes from 'prop-types';
import { useEffectOnChange } from 'utils/hooks';
import { formatText } from 'utils/formatting';
import { useSimpleTexts } from 'components/sanaText';

const quantityInputRegex = /^\d*(?:[.,]\d*)?$/;

const VolumeCalculator = ({
    id = 'squreMeter_Input',
    disabled = false,
    separator,
    volumeModel,
    quantity,
    quantityBoxValue,
    onChange,
    userIntervention,
    itmPriceLoaded }) => {

    const { minimum, maximum } = quantity;
    const inputRef = useRef();
    const displayRefs = useDisplayRef(separator);
    const formatNumber = useFormatNumberWithCulture();
    const currencyInfo = useCurrencyInfo();
    const [calculationStatus, setCalculationStatus] = useState({
        calculationDirectionForward: false,
    });

    const
        { amountNeededInSqMtr,
          cuttingLossIncluded,
          cuttingLossPercentage,
          totAmtInSQMeters,
          totAmtInPieces,
          totPriceOfPieces,
          volumeConversionRate,
          pricePerPiece } = volumeModel;

    const [volModelLables, setVolModelLables] =
        useState({
            amountNeededInSqMtr, cuttingLossIncluded, totAmtInSQMeters, totAmtInPieces, totPriceOfPieces,
        });

    function useDisplayRef(separator) {
        const displayRef = useRef();
        if (!displayRef.current)
            displayRef.current = { separator };

        return displayRef.current;
    }

    useEffectOnChange(() => {
        setCalculationStatus(prevState => ({
            ...prevState,
            calculationDirectionForward: true,
        }));
    }, [volModelLables.cuttingLossIncluded]);

    useEffectOnChange(() => {
        if(userIntervention){
            setCalculationStatus(prevState => ({
                ...prevState,
                calculationDirectionForward: false,
            }));
        }
    }, [quantityBoxValue]);

    useEffectOnChange(() => {
        backwardVolumeCalculation();
    }, [itmPriceLoaded]);

    useEffectOnChange(() => {
        if (calculationStatus.calculationDirectionForward) {
            forwardVolumeCalculation();
        }
        else {
            backwardVolumeCalculation();
        }
    }, [calculationStatus]);

    const handleChange = e => {
        const newQuantity = e.target.value;
        if (!quantityInputRegex.test(newQuantity))
            return;

        const [number, separator] = getNumberAndSeparator(newQuantity);
        displayRefs.number = number;
        displayRefs.stringValue = newQuantity;

        if (separator)
            displayRefs.separator = separator;        
            setVolModelLables(prevState => ({
                ...prevState,
                amountNeededInSqMtr: number,
            }));
            setCalculationStatus(prevState => ({
                ...prevState,
                calculationDirectionForward: true,
            }));
    };

    const handleChkboxChange = e => {
        setVolModelLables(prevState => ({
            ...prevState,
            cuttingLossIncluded: !prevState.cuttingLossIncluded,
        }));
    };

    const forwardVolumeCalculation = () => {
        let [tAmtinSqM, tAmtInPieces, tPriceOfPieces] = [0, 0, 0];        
        const [numAmtNeeded] = getNumberAndSeparator(volModelLables.amountNeededInSqMtr);

        if (!volModelLables.cuttingLossIncluded) {
            tAmtinSqM = numAmtNeeded;
        }
        else {
            tAmtinSqM = ((numAmtNeeded * (100 + cuttingLossPercentage)) / 100);
        }
        tAmtInPieces = Math.ceil(tAmtinSqM / volumeConversionRate);
        if(pricePerPiece > 0){
            tPriceOfPieces = tAmtInPieces * pricePerPiece;
        }
        else{
            tPriceOfPieces = 0;
        }        
        const tAmtinSqMFloored = Math.floor(tAmtinSqM*10000)/10000;

        setVolModelLables(prevState => ({
            ...prevState,
            totAmtInSQMeters: tAmtinSqMFloored,
            totAmtInPieces: tAmtInPieces,
            totPriceOfPieces: tPriceOfPieces,
        }));
        onChange({ value: tAmtInPieces, isValid: true, userChanged: false });
    };

    const backwardVolumeCalculation = () => {
        let [AmtinSqM, tAmtinSqM, tAmtInPieces, tPriceOfPieces] = [0, 0, 0];
        if(pricePerPiece > 0){
            tPriceOfPieces = quantityBoxValue * pricePerPiece;
        }
        else{
            tPriceOfPieces = 0;
        }
        tAmtInPieces = quantityBoxValue;
        tAmtinSqM = quantityBoxValue * volumeConversionRate;        
        if (volModelLables.cuttingLossIncluded) {
            AmtinSqM = ((tAmtinSqM / (100 + cuttingLossPercentage)) * 100);            
        }
        else {
            AmtinSqM = tAmtinSqM;
        }

        tAmtinSqM = Math.floor(tAmtinSqM*100)/100;
        AmtinSqM = Math.floor(AmtinSqM*100)/100;
        AmtinSqM = Number(AmtinSqM).toFixed(2);

        setVolModelLables(prevState => ({
            ...prevState,
            totPriceOfPieces: tPriceOfPieces,
            totAmtInPieces: tAmtInPieces,
            totAmtInSQMeters: tAmtinSqM,
            amountNeededInSqMtr: AmtinSqM,
        }));
        displayRefs.number = AmtinSqM;
        displayRefs.stringValue = AmtinSqM.toString();
    };

    const [cuttingLossText] = useSimpleTexts(['IncludeCuttingLossPercentage']).texts;

    return (

        <div className="volumetbl_align">
            <Table>
                <tbody>
                    <tr>
                        <td>
                          <SimpleText textKey="AmountNeededInMSq" /><sup>2</sup>
                        </td>
                        <td className="volume_input_align">
                            <input id={id}
                              ref={inputRef}
                              type="text"
                              role="spinbutton"
                              disabled={disabled}
                              maxLength="8"
                              value={displayRefs.stringValue || ''}
                              onChange={handleChange}
                              aria-valuemax={maximum}
                              aria-valuemin={minimum}
                              aria-valuenow={displayRefs.number || Number(displayRefs.stringValue) || 0}
                              aria-keyshortcuts="ArrowUp ArrowDown"
                              autoComplete="off"
                            />                            
                        </td>
                    </tr>
                    <tr>
                        <td>
                            {formatText(cuttingLossText,[cuttingLossPercentage])}
                        </td>
                        <td className="volume_chckbox_align">
                            <input type="checkbox" onChange={handleChkboxChange} checked={volModelLables.cuttingLossIncluded} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SimpleText textKey="TotalAmountInSqureMeters" /><sup>2</sup>
                        </td>
                        <td className="txt_align_right">
                            {/* {formatNumber(volModelLables.totAmtInSQMeters)} */}
                            {(volModelLables.totAmtInSQMeters)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SimpleText textKey="TotalAmountInPieces" />
                        </td>
                        <td className="txt_align_right">
                            {formatNumber(volModelLables.totAmtInPieces)}
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <SimpleText textKey="TotalPriceOfPieces" />
                        </td>
                        <td className="txt_align_right">
                            {formatAsPrice(volModelLables.totPriceOfPieces, currencyInfo)}
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default memo(VolumeCalculator);

VolumeCalculator.propTypes = {
    id: PropTypes.string,
    quantity: PropTypes.shape({
        step: PropTypes.number.isRequired,
        minimum: PropTypes.number.isRequired,
        maximum: PropTypes.number.isRequired,
        allowEmpty: PropTypes.bool.isRequired,
    }),
    quantityBoxValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    onChange: PropTypes.func.isRequired,
    userIntervention: PropTypes.bool,
    tooltipOptions: PropTypes.object,
    separator: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    itmPriceLoaded: PropTypes.bool,
    volumeModel: PropTypes.shape({
        amountNeededInSqMtr: PropTypes.number,
        cuttingLossIncluded: PropTypes.bool,
        cuttingLossPercentage: PropTypes.number,
        totAmtInSQMeters: PropTypes.number,
        totAmtInPieces: PropTypes.number,
        totPriceOfPieces: PropTypes.number,
        volumeConversionRate: PropTypes.number,
        pricePerPiece: PropTypes.number,
    }),
};

function getNumberAndSeparator(numberStr) {
    if (typeof numberStr !== 'string' || !numberStr)
        return [numberStr, null];

    let separator;
    const stringWithDot = numberStr.replace(',', match => {
        separator = match;
        return '.';
    });
    return [parseFloat(stringWithDot), separator];
}