const initalVolCalcModel = {
    amountNeededInSqMtr: 0,
    cuttingLossIncluded : false,
    cuttingLossPercentage : 0,
    totAmtInSQMeters : 0,
    totAmtInPieces : 0 ,
    totPriceOfPieces : 0,
    volumeConversionRate : 0,
    pricePerPiece : 0,    
};

export const createVolumeCalculationModel = () => {
    const volCalcModel = initalVolCalcModel;
    return volCalcModel;
};